<template>
  <div class="page">
    <div class="top">
      <h3>寓信公寓管理系统信创解决方案</h3>
      <h1>赋能公寓管理系统信创体系建设</h1>
      <h4>全栈适配 / 自主可控 / 安全稳定</h4>
    </div>
    <div class="solve">
      <div class="part">
        <h2>公寓管理系统信创解决方案</h2>
        <h4>全栈适配 自主可控 安全稳定</h4>
        <div class="items">
          <div class="item">
            <img src="@/assets/image/plan/icon1.png" alt="" />
            <h4>全栈适配</h4>
            <p>寓信信创版产品全栈适配信创环境，支持从芯片、操作系统、数据库、中间件等主流软硬件产品，覆盖客户端到服务端所有功能场景，确保系统的稳定运行，帮助各类政企在实现数字化转型和数据驱动决策的同时，自主可控、安全合规。</p>
          </div>
          <div class="item">
            <img src="@/assets/image/plan/icon2.png" alt="" />
            <h4>自主可控</h4>
            <p>寓信携手国产信息安全厂商共同打造符合信创发展要求的行业应用；通过自研底层核心平台，使得用户实现底层代码的自主可控，通过多个维度的优化，尽可能的提高数字化协同平台在信创底层软硬件环境的运行效率。</p>
          </div>
          <div class="item">
            <img src="@/assets/image/plan/icon3.png" alt="" />
            <h4>安全稳定</h4>
            <p>为公寓管理系统各个功能模块置入满足合规性要求的安全功能，确保该系统能够满足国家及地方政策的安全与合规性标准，有效规避潜在的风险。保护数据安全及系统的稳定运行。致力于为公寓管理者和居民打造一个安全、可靠的居住环境。</p>
          </div>
        </div>
      </div>
    </div>
      <div class="system">
        <div class="part">
          <h2>全栈适配国产伙伴产品，打造信创生态体系</h2>
          <h4>从底层CPU、服务器、中间件、数据库等环境进行全面信创支持，打造全生态信创平台</h4>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "plan",
  data() {
    return {
      screenWidth: document.body.clientWidth
    }
  },
  methods: {},
  created() {
    const that = this
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  }
}
</script>

<style scoped lang="less">
h1, h2, h3, h4, p {
  margin-bottom: 0;
}
.top {
  height: 550px;
  padding-top: 70px;
  background: url("../assets/image/plan/bg.png") no-repeat center;
  background-size: cover;
  h3 {
    width: 1200px;
    margin: 0 auto;
    padding-top: 120px;
    font-size: 26px;
    color: #ffffff;
  }
  h1 {
    color: #ffffff;
    width: 1200px;
    margin: 0 auto;
    font-size: 44px;
    font-weight: bold;
  }
  h4 {
    color: #ffffff;
    width: 1200px;
    margin: 0 auto;
    font-size: 16px;
    padding-top: 10px;
  }
}
.part {
  width: 1200px;
  margin: 0 auto;
  padding: 54px 0;
  text-align: center;
  h2 {
    font-weight: 700;
    font-size: 36px;
    color: #030d1e;
  }
  h4 {
    margin-bottom: 40px;
    font-size: 20px;
    color: #545558;
    font-weight: 400;
  }
}
.solve {
  background-color: #f1f5fd;
  .items {
    display: flex;
    .item {
      padding: 42px;
      border: 2px solid #ffffff;
      box-shadow: 0px 0px 15px 0px rgba(157,178,222,0.3);
      border-radius: 8px;
      flex: 1;
      img {
        height: 56px;
      }
      h4 {
        font-size: 20px;
        margin: 20px 0;
        font-weight: bold;
      }
      p {
        font-size: 16px;
        color: #808082;
        text-align: left;
        line-height: 32px;
      }
    }
    .item + .item {
      margin-left: 24px;
    }
  }
}
.system {
  height: 900px;
  background: url("../assets/image/plan/system.png") no-repeat center;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  .page {
    width: 100vw;
    overflow: hidden;
  }
  .top {
    height: 200px;
    background: url("../assets/image/plan/bg-m.png") no-repeat center;
    background-size: cover;
    margin-top: 50px;
    padding: 0 12px;
    h3 {
      padding: 60px 0 4px;
      font-size: 14px;
    }
    h1 {
      font-size: 18px;
    }
    h4 {
      padding-top: 4px;
      font-size: 14px;
    }
  }
  .part {
    width: 100%;
    padding: 20px 0 14px;
    h2 {
      font-size: 16px;
    }
    h4 {
      font-size: 12px;
      margin-bottom: 10px;
    }
  }
  .solve {
    .items {
      display: initial;
      .item {
        margin: 20px 24px !important;
        padding: 20px 24px;
        img {
          height: 46px;
        }
        h4 {
          font-size: 14px;
          margin: 10px 0;
        }
        p {
          font-size: 12px;
          line-height: 24px;
        }
      }
    }
  }
  .system {
    height: 320px;
    background: url("../assets/image/plan/system-m.png") no-repeat center;
    background-size: cover;
    padding: 0 10px;
  }
}
</style>